define(['enhancedEcommerce', 'app', 'jquery', 'siteObj'], function(enhancedEcommerce, app, $, siteObj) {
  'use strict';
  // beforeEach(function() {
  //   dom.render();
  // });

  describe('enhancedEcommerce', function() {

    it('should return an object', function() {
      expect(enhancedEcommerce).toEqual(jasmine.any(Object));
    });

    describe('enEcommerceValues', function() {

      it('should be equal to a function', function() {
        expect(enhancedEcommerce.enEcommerceValues).toEqual(jasmine.any(Function));
      });

    });

    describe('sendValues', function() {

      it('should be equal to a function', function() {
        expect(enhancedEcommerce.sendValues).toEqual(jasmine.any(Function));
      });

      it('should call app.publish', function() {

        spyOn(app, ['publish']);

        var clickElement = document.querySelectorAll('.click-element-ecommerce-class')[0];

        enhancedEcommerce.sendValues({
          target: clickElement
        }, 'click-element-ecommerce-class');

        expect(app.publish).toHaveBeenCalled();

      });

      it('should not call app.publish if class does not match', function() {

        spyOn(app, ['publish']);

        var clickElement = document.querySelectorAll('.click-element-ecommerce-class')[0];

        enhancedEcommerce.sendValues({
          target: clickElement
        }, 'not-the-correct-class');


        expect(app.publish).not.toHaveBeenCalled();

      });

    });

    describe('clearSiteObjValues', function() {
      it('should be equal to a function', function() {
        expect(enhancedEcommerce.clearSiteObjValues).toEqual(jasmine.any(Function));
      });

      it('should delete productVariationId and productTitle', function() {
        var input = {
          productId: 10530943,
          title: 'another test product'
        };
        enhancedEcommerce.productPageVariations(input);
        expect(siteObj.productVariationID).toBe('10530943');
        expect(siteObj.productTitle).toBe('another test product');

        enhancedEcommerce.clearSiteObjValues();

        expect(siteObj.productVariationID).toBe(null);
        expect(siteObj.productTitle).toBe(null);
      });
    });

    describe('productPageVariations', function() {
      it('should be equal to a function', function() {
        expect(enhancedEcommerce.productPageVariations).toEqual(jasmine.any(Function));
      });

      it('should delete productVariationId and productTitle', function() {
        spyOn(enhancedEcommerce, ['clearSiteObjValues']);

        var input = {
          productId: 123456,
          title: 'another test product'
        };
        enhancedEcommerce.productPageVariations(input);

        expect(enhancedEcommerce.clearSiteObjValues).toHaveBeenCalled();
        expect(siteObj.productVariationID).toBe('123456');
        expect(siteObj.productTitle).toBe('another test product');
      });
    });
  });

});
